<template>
  <div class="wrap">
    <img class="notfound" src="@/assets/images/404.png" alt="" />
    <div class="backHome" @click="backHome">返回首页</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'notfound',
  computed: {
    ...mapState(['mobile'])
  },
  mounted() {
    if (this.mobile) {
      this.size = true
      let navBar = document.getElementsByClassName('navWrap')[0]
      navBar.style.display = 'none'
      let bottom = document.getElementsByClassName('bottom')[0]
      bottom.style.display = 'none'
    }
  },
  methods:{
    backHome(){
      this.$router.push('/')
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  min-height: 500px;
  position: relative;
  .notfound {
    display: block;
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
  .backHome{
    display: none;
  }
}
@media screen and (max-width: 760px) {
  .wrap {
    width: 100%;
    min-height: 400px;
    position: relative;
    .notfound {
      width: 80%;
      display: block;
      position: absolute;
      left: 50%;
      top: 55%;
      transform: translate(-50%, -50%);
    }
    .backHome {
      display: block;
      width: 80px;
      height: 0.44rem;
      padding: 0 6px;
      font-size: 0.28rem;
      color: #fff;
      background: #d8dadb;
      border-radius: 10px;
      text-align: center;
      line-height: 0.44rem;
      position: absolute;
      left: 50%;
      top: 82%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
